import React from "react"
import Layout from "../layout/layout"


const WebPage = () => (
    <Layout>
        <header className="post-header">
            <h1 className="post-title">Web Projects</h1>
        </header>
        <article className="post-content">
            <p>I work as a developer and ux/ui designer for local and international brands.<br />I'm available for interesting freelance projects just contact me.</p>
            <p><strong>Porftolio</strong></p>
            <ul>
                <li>Github: <a href="https://github.com/4me7h/" target="_blank" rel="noreferrer">@4me7h</a></li>
                <li>Instagram: <a href="https://www.instagram.com/creativelab.dev/" target="_blank" rel="noreferrer">@creativelab.dev</a></li>
                <li>Behance: <a href="https://www.behance.net/creativelabdev" target="_blank" rel="noreferrer">@creativelabdev</a></li>
            </ul>
            <p><strong>Some brands with which I’ve worked or collaborated</strong></p>
            <ul>
                <li>Gaia</li>
                <li>Pert Plus</li>
                <li>Sura</li>
                <li>Club Premier</li>
                <li>Aeroméxico</li>
                <li>Vitacilina</li>
                <li>Rocainul</li>
                <li>Capistrano</li> 
                <li>Quick Learning</li> 
                <li>KP Alazraki</li> 
                <li>Digital Mind</li>
                <li>Globos Payaso</li> 
            </ul>           
            <p><strong>Some web projects which I’ve developed or collaborated:</strong></p>
            <ul>
                <li><a href="http://kp.com.mx" target="_blank" rel="noreferrer">KP</a></li>
                <li><a href="https://gaiadesign.com.mx" target="_blank">Gaia Design</a></li>
                <li><a href="https://sinergia.io" target="_blank" rel="noreferrer">Sinergia Binaria</a></li>
                <li><a href="https://elrincon-verde.club" target="_blank" rel="noreferrer">El Rincon Verde</a></li>
                <li><a href="https://segurosdentales.com.mx" target="_blank" rel="noreferrer">Dentegra</a></li>
                <li><a href="https://impoc.us" target="_blank" rel="noreferrer">Impoc</a></li>
                <li><a href="https://iceo.mx" target="_blank" rel="noreferrer">Iceo</a></li>
                <li><a href="https://tekbom.mx" target="_blank" rel="noreferrer">Tekbom</a></li>
                <li><a href="http://manhattangroup.com.mx" target="_blank" rel="noreferrer">Manhattan Group</a></li>
                <li><a href="https://arasesores.mx" target="_blank" rel="noreferrer">Arasesores</a></li>
                <li><a href="https://ambiant.com.mx" target="_blank" rel="noreferrer">Ambiant</a></li>
                <li><a href="https://globospayaso.com" target="_blank" rel="noreferrer">Globos Payaso</a></li>
            </ul>
            <p>
                Follow me on Instagram <a href="https://instagram.com/4me7h/" target="_blank" rel="noreferrer">@4me7h</a>
            </p>
        </article>
    </Layout>
)

export default WebPage